<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Broker'"/>
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/broker`"
                title="Broker"
                :columns="columns"
                routerpath="/master/account/addbroker"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import { BRow, BCol } from "bootstrap-vue";

import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
import TabPosition from "../../../../components/TabView.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;

export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
    BRow,
    BCol,
  },
  data() {
    return {
      edit: "",
      add: "",
      canDelete: "",
      // tabs: [
      //   { name: 'Ledger', route: '/master/accounting/ledger', icon: 'WindIcon' },
      //   { name: 'AccountGroup', route: '/master/accounting/accountgroup', icon: 'UsersIcon' },
      //   { name: 'BankAccountMaster', route: '/master/accounting/bankaccount', icon: 'GridIcon' },
      //   { name: 'BankStatement', route: '/master/accounting/bankstatement', icon: 'FileTextIcon' },
      //   { name: 'Vendor', route: '/master/accounting/vendor', icon: 'SidebarIcon' },
      //   { name: 'Vendortype', route: '/master/accounting/vendortype', icon: 'TypeIcon' },
      //   { name: 'Vendorcategory', route: '/master/accounting/vendorcategory', icon: 'OctagonIcon' },
      //   { name: 'Broker', route: '/master/accounting/broker', icon: 'UserIcon' },
      //   { name: 'Farmer', route: '/master/accounting/farmer', icon: 'FramerIcon' },
      //   { name: 'PurchaseBuyer', route: '/master/accounting/purchasebuyer', icon: 'TriangleIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Broker" : "Add Broker"}`,
        submitRouterPath: "/master/account",
        usersDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/broker`,
        getEditValue: `${baseApi}/getbrokerById`,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          // {
          //   name: "nextfollowupdate",
          //   value: `${moment(new Date()).format("DD/MM/yyyy")}`,
          //   type: "hidden",
          // },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Voucher NO",
            name: "voucherno",
            value: "",
            type: "number",
            placeholder: "Enter Voucher Number",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Date",
            name: "date",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select date",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Cheque NO",
            name: "chequeno",
            value: "",
            type: "number",
            placeholder: "Enter Cheque Number",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Client Name",
            name: "clientname",
            value: "",
            type: "text",
            placeholder: "Enter Client Name",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Company Name",
            name: "companyname",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "companyname",
            options: [],
            // required: {
            //   required,
            // },
          },
          {
            label: "Bank Name",
            name: "bankname",
            value: "",
            type: "text",
            placeholder: "Enter Bank Name",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Amount",
            name: "amount",
            value: "",
            type: "text",
            placeholder: "Enter Amount",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Cheque Date",
            name: "chequedate",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select Cheque date",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Reason",
            name: "reason",
            value: "",
            type: "text",
            placeholder: "Enter Reason",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Client's Bank",
            name: "clientbank",
            value: "",
            type: "text",
            placeholder: "Enter Client's Bank",
            class: "col-md-6",
          },
        ],
      },
      viewFields: [
        {
          label: 'Profile Image',
          field: 'profile_image',
          type: 'image',
          class: "col-md-12",
        },
        {
          label: 'Surname',
          field: 'surname',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Buyer Name',
          field: 'name',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Father/Husband Name',
          field: 'fathername',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Mobile No',
          field: 'mobile',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Email',
          field: 'email',
          type: 'email',
          class: "col-md-6",
        },
        {
          label: 'Address 1',
          field: 'addressline1',
          type: 'text',
          class: "col-md-12",
        },
        {
          label: 'Address 2',
          field: 'addressline2',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Address 3',
          field: 'addressline3',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Pincode',
          field: 'pincode',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'City',
          field: 'pertaluka',
          type: 'text',
          class: "col-md-4",
        }, {
          label: 'Area',
          field: 'area',
          type: 'text',
          class: "col-md-4",
        },
        {
          label:'State',
          field:'state',
          type:'text',
          class:'col-md-4'
        },
        {
          label: 'Gender',
          field: 'gender',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Aadhar Card No',
          field: 'aadharcardno',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Pan No',
          field: 'panno',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Aadhar Card Front Side',
          field: 'aadharfrontside',
          type: 'attachment',
          class: "col-md-4",
        },
        {
          label: 'Aadhar Card Back Side',
          field: 'aadharbackside',
          type: 'attachment',
          class: "col-md-4",
        },
        {
          label: 'Pan Card',
          field: 'pancard',
          type: 'attachment',
          class: "col-md-4",
        },
        {
          field: 'BuyerDocuments',
          tableHead: ['DOCUMENT NAME', 'DOCUMENT ATTACHMENT','REMARK',],
          tableField: [
            {
              type: 'text',
              field: 'documentname',
            },
            {
              type: 'attachment',
              field: 'documentattached',
            },
            {
              type: 'text',
              field: 'remarks',
              class:'col-md-3'
            },
          ],
          type: 'multiple',
          class: "col-md-12",
        },
      ],
      columns: [
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Mobile No",
          field: "mobile",
          filterOptions: {
            enabled: true,
            placeholder: "Search Mobile No",
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search email",
          },
        },
        {
          label: "City",
          field: "city",
          filterOptions: {
            enabled: true,
            placeholder: "Search City",
          },
        },
        {
          label: "State",
          field: "state",
          filterOptions: {
            enabled: true,
            placeholder: "Search State",
          },
        },

         {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
         {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Broker") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;

          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
